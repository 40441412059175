@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  font-family: 'Montserrat', sans-serif !important;
  background-color: black;
  max-height: 100vh !important;
}

#heroSection {
  height: 100vh;
}

#heroSection header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}

#heroSection video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#heroSection:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 10%, rgba(0,0,0,0) 20%);
  /* background: rgba(0,0,0,0.8); */
  /* backdrop-filter: blur(10px); */
  z-index: 1;
}

#test {
  color: white;
  font-size: 100px;
  margin-bottom: 0px;
}

header {
  padding: 1rem 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* position: sticky; */
  /* top: 0px;
  z-index: 9999; */
}

.navbarItem {
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  margin-right: 2rem;
  cursor: pointer;
}

header svg {
  width: 40px;
  height: 40px;
  color: #fdf100;
}

#sidebar {
  background-color: #111111;
  position: absolute;
  right: 0px;
  height: 100vh;
  width: 75%;
  z-index: 1001;
}

#sidebarContainer {
  padding: 1rem;
}

#sidebar svg {
  color: #fdf100;
  width: 35px;
  height: 35px;
  margin-bottom: 2rem;
}

#sidebar .navbarItem {
  color: white;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  margin-right: 0px;
  cursor: pointer;
  margin-bottom: 2rem;
}

#sidebar .primaryBtn {
  background-color: #fdf100;
  color: #111111;
  font-size: 24px;
  font-weight: 700;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  height: 56px;
  border-radius: 0px;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  width: 100%;
}

h1 {
  color: white;
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 1rem;
}

.primaryBtn {
  background-color: #fdf100;
  color: #222222;
  font-size: 16px;
  font-weight: 700;
  border: none;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 40px;
  border-radius: 0px;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.primaryBtn:hover {
  background-color: #222222;
  color: white;
  font-size: 16px;
  font-weight: 700;
  border: none;
  height: 40px;
  border-radius: 0px;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.secondaryBtn {
  background-color: white;
  color: black;
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

#classesSection {
  padding: 1rem 5rem;
}

#teachersSection {
  padding: 1rem 5rem;
}

.teacher {
  width: 200px;
  margin-right: 3rem;
}

.teacher img {
  width: 200px;
  margin-bottom: 1rem;
}

.teacher h3 {
  color: white;
  margin: 0px;
  font-size: 24px;
  font-weight: 700;
}

#scheduleSection {
  padding: 1rem 5rem;
}

#schedule {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.scheduleDay {
  width: 400px;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.scheduleDay h3 {
  color: white;
  /* text-align: center; */
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin: 0px;
  padding: 1rem;
  font-size: 24px;
  font-weight: 700;
}

.classItem {
  color: white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin: 0px;
  padding: 1rem;
}

.scheduleDay p {
  margin: 0.5rem
}

.className {
  font-size: 20px;
}

.classTeacher {
  font-weight: 700;
  margin-bottom: 1rem !important;
}

.classTimeAndLevel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.classTime {
  
}

.classLevel {
  
}

#scheduleSection svg, #classesSection svg, #teachersSection svg {
  color: #fdf100;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

#showsSection {
  padding: 1rem 5rem;
}

#showsSection .showItem {
  cursor: pointer;
}

.latestShow {
  margin-bottom: 1rem;
}

.latestShow img {
  width: 100%;
}

.latestShowTextContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(10deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 12.5%, rgba(0,0,0,0) 25%);
  z-index: 1;
  padding-left: 1rem;
}

.latestShowTextContainer p {
  color: white;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
}

.latestShowTextContainer p span {
  font-size: 20px;
}

#shows svg{
  color: #fdf100;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.showItem {
  /* background-color: white; */
  margin-right: 1rem;
}

.showItem img {
  max-width: 300px;
}

#aboutSection {
  padding: 1rem 5rem;
}

.aboutContainer {
  background-color: white;
  text-align: center;
}

#contactSection {
  padding: 1rem 5rem;
}

#contactSection h3 {
  color: white;
  margin: 0px;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

#contactSection p {
  color: white;
  margin: 0px;
}

.mapContainer {
  width: 100%;
  height: 100vh
}

#faqSection {
  padding: 1rem 5rem;
}

.accordion-item {
  border: 1px solid white;
  background-color: black;
}

.accordion{
  /* --bs-accordion-border-radius: 10px; */
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-border-color: transparent;
}

.accordion-button {
  color: white !important;
  font-weight: bold;
}

.accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
  color: white;
}

.accordion-button:not(.collapsed){
  background-color: transparent;
}

.accordion-button::after {
  margin-bottom: -0.5rem;
}

.accordion-button:not(.collapsed)::after {
  margin-top: -1rem;
}

.accordion {
  --bs-accordion-btn-icon: url("https://res.cloudinary.com/da3qtg7t5/image/upload/v1690518444/General%20use/Vector_ks6abz.svg");
  --bs-accordion-btn-active-icon: url("https://res.cloudinary.com/da3qtg7t5/image/upload/v1690518444/General%20use/Vector_ks6abz.svg");
}

.show {
  background-color: black;
}

h2.accordion-header {
  margin-bottom: 0px !important;
}

.accordion-body{
  padding-top: 0px;
  color: white;
}

#singleClassContainer {
  background-color: #111111;
  padding: 1rem;
}

#singleTeacherContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #111111;
  padding: 1rem;
  margin-top: 1rem;
}

#singleTeacherContainer svg, #singleClassContainer svg {
  color: #fdf100;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

#singleTeacherContainer h1 {
  margin-bottom: 0px;
}

#singleTeacherContainer p {
  color: #FFFFFF;
}

#singleTeacherContainer h3 {
  color: white;
  margin: 0px;
  font-size: 24px;
  font-weight: 700;
}

#desktopFooter {
  padding: 2rem 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#desktopFooter p, #desktopFooter a {
  color: white;
  margin-right: 2rem;
  text-decoration: none;
}

#mobileFooter {
  padding: 2rem 1rem;
  text-align: center;
}

footer p, footer a {
  color: white;
  margin: 0px;
  text-decoration: none;
}

.socialsIconContainer {
  width: 50px;
  height: 50px;
  background-color: #fdf100;
  border-radius: 100px;
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

footer svg {
  width: 20px;
  height: 20px;
  color: black
}

#comingSoonTextContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0px;
  right: 0px;
  background-color: transparent;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#comingSoonTextContainer {
  color: #FFFFFF10;
  font-size: 75px;
  font-weight: bold;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #fdf100;
  color: #222222;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.whatsapp_float:hover {
  color: black;
}

.whatsapp-icon {
  margin-top: 9px;
}

#aboutContainer {
  background-image: url('https://res.cloudinary.com/da3qtg7t5/image/upload/q_auto/f_auto/v1697586969/LMDS/fotoGeneral_olchxu.png');
  background-size: cover;
  background-position: bottom;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#aboutContainer p {
  color: #FFFFFF;
  margin-bottom: 0px;
  width: 50%;
  text-align: center;
  font-weight: 500;
}

.classObject {
  width: 350px;
  margin-right: 3rem;
  background-color: #111111;
  padding: 1rem;
  height: 100%;
}

.classObject h3 {
  color: white;
  margin-bottom: 0.5rem;
  font-size: 20px;
  font-weight: 700;
}

.classObject .teacherAndLevel{
  color: white;
  margin: 0px;
  /* font-size: 24px; */
  /* font-weight: 500; */
}

.classObject .description {
  color: white;
  margin: 0px;
  margin-top: 1rem;
  /* font-size: 24px; */
  /* font-weight: 500; */
}

.accordion-item:first-of-type {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.accordion-item:last-of-type {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

@media only screen and (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }

  #mobileFooter {
    display: none;
  }

  header svg {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }

  header {
    padding: 1rem 1rem;
  }

  #desktopNavbarItems {
    display: none !important;
  }

  #classesSection {
    padding: 1rem 1rem
  }

  #teachersSection {
    padding: 1rem 1rem
  }

  .teacher {
    width: 200px;
    margin-right: 1rem;
  }

  .teacher img {
    width: 200px;
    margin-bottom: 1rem;
  }
  
  #scheduleSection {
    padding: 1rem 1rem
  }

  .scheduleDay {
    width: 300px;
  }
  
  #showsSection {
    padding: 1rem 1rem
  }

  .latestShow {
    display: flex;
    align-items: center;
  }

  .latestShowTextContainer p {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }

  .latestShowTextContainer p span {
    font-size: 16px;
  }

  .latestShowTextContainer {
    padding-left: 0.5rem;
    background: linear-gradient(10deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 25%, rgba(0,0,0,0) 50%);
  }

  .latestShow img{
    max-width: 100%;
  }

  .show img {
    max-width: 200px;
  }

  .showItem img {
    max-width: 200px;
  }
  
  #aboutSection {
    padding: 1rem 1rem
  }

  .aboutContainer {
    height: 500px;
    display: flex;
    align-items: center;
  }

  .aboutContainer img{
    max-width: 100%;
  }

  #aboutContainer {
    padding: 1rem;
  }

  #aboutContainer p {
    text-align: center;
    width: 100%;
  }
  
  #contactSection {
    padding: 1rem 1rem
  }

  #contactSection svg {
    margin-bottom: -0.1rem !important;
  }
  
  #faqSection {
    padding: 1rem 1rem
  }

  #desktopFooter {
    display: none;
  }

  footer p, footer a {
    display: block;
  }

  .socialsIconContainer {
    margin-left: 0px;
  }

  #singleTeacherSection {
    padding: 1rem;
  }

  #singleTeacherContainer {
    flex-direction: column;
  }

  #singleTeacherContainer img {
    width: 100%;
    margin-bottom: 1rem;
  }

  #singleTeacherTextContainer {
    width: 100%;
  }

  .classObject {
    margin-right: 1rem;
  }

  #comingSoonTextContainer {
    padding: 1rem;
  }
  
  #comingSoonTextContainer {
    text-align: center;
    line-height: 50px;
  }
}